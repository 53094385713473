@import "@/styles/variables.scss"; 





























































































































































































































































































































































































































































































































.warp {
  flex: 1;
  overflow: hidden;
  table {
    padding: 0;
    tr:hover {
      background-color: rgba(255, 255, 255, 0.05);
    }
  }
}
.over-item:hover {
  cursor: pointer;
}
